export function getStatusIcon(status: string): string {
  switch (status) {
    case 'end':
    case 'environment_finished':
      return 'success-color';
    case 'error':
      return 'error-color';
    default:
      return 'pending-color';
  }
}

export function getBooleanStatusIcon(value: boolean): string {
  switch (value) {
    case true:
      return 'success-color';
    case false:
      return 'error-color';
    case null:
      return 'pending-color';
  }
}

export function generateBreadCrumbsItems(url: string): any[] {
  const items = [];
  let firstIteration = true;
  let localUrl;
  // if there are url parameters they are removed, because otherwise the generated breadcrumbs are not correct
  if (url.lastIndexOf('?') > -1) {
    localUrl = url.substring(0, url.lastIndexOf('?'));
  } else {
    localUrl = url;
  }
  while (localUrl) {
    const lastSlashIndex = localUrl.lastIndexOf('/');
    const name = localUrl.substring(lastSlashIndex + 1, localUrl.length);
    if (firstIteration) {
      items.unshift({ label: getLabel(name) });
      firstIteration = false;
    } else {
      items.unshift({ label: getLabel(name), path: localUrl });
    }
    let prevUrl = localUrl.substring(0, lastSlashIndex);
    const lastItem = prevUrl.substring(prevUrl.lastIndexOf('/') + 1, prevUrl.length);
    if (Number(lastItem)) {
      prevUrl = prevUrl.substring(0, prevUrl.lastIndexOf('/'));
    }
    localUrl = prevUrl;
  }
  items.unshift({ label: 'Main Actions', path: '/' });
  return items;
}

function getLabel(name: string) {
  if (name === 'create-version') {
    return 'Version editor';
  } else {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
}
